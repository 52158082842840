import { default as _91slug_93edncuuTnWGMeta } from "/root/front/pages/lp/[slug].vue?macro=true";
import { default as aboutCwNr95NlA2Meta } from "/root/front/pages/about.vue?macro=true";
import { default as _91id_93iMYJBkgu4BMeta } from "/root/front/pages/agency/favorites/[id].vue?macro=true";
import { default as indexpNPojHfcp7Meta } from "/root/front/pages/agency/favorites/index.vue?macro=true";
import { default as create_45draftpKoo2f8BlgMeta } from "/root/front/pages/band/create-draft.vue?macro=true";
import { default as emailsiBhqZ7A8GBMeta } from "/root/front/pages/band/dashboard/emails.vue?macro=true";
import { default as indexBkinn2ADnhMeta } from "/root/front/pages/band/dashboard/index.vue?macro=true";
import { default as dashboardQ0wsN6fQU1Meta } from "/root/front/pages/band/dashboard.vue?macro=true";
import { default as indexkJBxZ5jReMMeta } from "/root/front/pages/band/edit/track/[id]/campaign/[campaignId]/index.vue?macro=true";
import { default as indexhhHcmvdak8Meta } from "/root/front/pages/band/edit/track/[id]/index.vue?macro=true";
import { default as homepageYpHVGeyLjwMeta } from "/root/front/pages/band/homepage.vue?macro=true";
import { default as onboarding9PTI0SNpbjMeta } from "/root/front/pages/band/onboarding.vue?macro=true";
import { default as parameterQVjqIQIu4jMeta } from "/root/front/pages/band/parameter.vue?macro=true";
import { default as payinInHdtomh3jMeta } from "/root/front/pages/band/payin.vue?macro=true";
import { default as editPqVz7WjV8lMeta } from "/root/front/pages/band/profile/[slug]/edit.vue?macro=true";
import { default as indexg7U5PsOggkMeta } from "/root/front/pages/band/profile/[slug]/index.vue?macro=true";
import { default as create0Bd0WsPFoLMeta } from "/root/front/pages/band/profile/create.vue?macro=true";
import { default as deleteaRA1pcvzRJMeta } from "/root/front/pages/band/profile/delete.vue?macro=true";
import { default as indexG9hLdub9L3Meta } from "/root/front/pages/band/profile/index.vue?macro=true";
import { default as selectMJYWvPJonnMeta } from "/root/front/pages/band/profile/select.vue?macro=true";
import { default as _91code_93tmy2kpqnPRMeta } from "/root/front/pages/band/referral/[code].vue?macro=true";
import { default as indexT58W6sSCY9Meta } from "/root/front/pages/band/referral/index.vue?macro=true";
import { default as sendtrack5lizRzTiYFMeta } from "/root/front/pages/band/sendtrack.vue?macro=true";
import { default as finalizedOMzXYtNZ4XMeta } from "/root/front/pages/band/shop/finalized.vue?macro=true";
import { default as indexvTZo9RqsMrMeta } from "/root/front/pages/band/shop/index.vue?macro=true";
import { default as stripe7GfnQZTKRNMeta } from "/root/front/pages/band/shop/processing/stripe.vue?macro=true";
import { default as discoveryBnDnIPiA4EMeta } from "/root/front/pages/band/signup/discovery.vue?macro=true";
import { default as indexSeO6A7IHADMeta } from "/root/front/pages/band/signup/index.vue?macro=true";
import { default as _91code_93RlPi5TLtnpMeta } from "/root/front/pages/band/signup/referral/agency/[code].vue?macro=true";
import { default as _91code_93wjcAmXmeeCMeta } from "/root/front/pages/band/signup/referral/influencer/[code].vue?macro=true";
import { default as cgsDXgBz4e9lMMeta } from "/root/front/pages/cgs.vue?macro=true";
import { default as contactWFTDpTynm3Meta } from "/root/front/pages/contact.vue?macro=true";
import { default as finalizedhYPaDf3bdTMeta } from "/root/front/pages/draft/[id]/finalized.vue?macro=true";
import { default as messages80XdMgrrOwMeta } from "/root/front/pages/draft/[id]/index/messages.vue?macro=true";
import { default as recap0TB2JcmMdfMeta } from "/root/front/pages/draft/[id]/index/recap.vue?macro=true";
import { default as shared_45musicT1OdrRmn64Meta } from "/root/front/pages/draft/[id]/index/shared-music.vue?macro=true";
import { default as trackuIjaS61H1pMeta } from "/root/front/pages/draft/[id]/index/track.vue?macro=true";
import { default as indexhR1daect7xMeta } from "/root/front/pages/draft/[id]/index.vue?macro=true";
import { default as indexlESNhhasq4Meta } from "/root/front/pages/draft/[id]/influencers/index.vue?macro=true";
import { default as listsM5hwW2O7EhMeta } from "/root/front/pages/draft/[id]/influencers/lists.vue?macro=true";
import { default as loadingdH0s0XYEyQMeta } from "/root/front/pages/draft/[id]/influencers/loading.vue?macro=true";
import { default as influencersZDBqKdhyGNMeta } from "/root/front/pages/draft/[id]/influencers.vue?macro=true";
import { default as post3dsqsGOnHS7y8Meta } from "/root/front/pages/draft/[id]/post3ds.vue?macro=true";
import { default as stripeolaD2EaAW3Meta } from "/root/front/pages/draft/[id]/processing/stripe.vue?macro=true";
import { default as indexLPiTjv4We6Meta } from "/root/front/pages/draft/index.vue?macro=true";
import { default as influencersES0i7yNr4dMeta } from "/root/front/pages/draft/influencers.vue?macro=true";
import { default as finalize_45authES9ZboEb3UMeta } from "/root/front/pages/finalize-auth.vue?macro=true";
import { default as activateLuTEMJoA7FMeta } from "/root/front/pages/influencer/activate.vue?macro=true";
import { default as cashoutzdSv1RjprAMeta } from "/root/front/pages/influencer/cashout.vue?macro=true";
import { default as dashboardwaqr7P28zFMeta } from "/root/front/pages/influencer/dashboard.vue?macro=true";
import { default as index6NaBTKhdUnMeta } from "/root/front/pages/influencer/list/all/index.vue?macro=true";
import { default as fansw15KVqFUBmMeta } from "/root/front/pages/influencer/list/fans.vue?macro=true";
import { default as new_45arrivalsbtJH3dXygAMeta } from "/root/front/pages/influencer/list/new-arrivals.vue?macro=true";
import { default as popular1lP3JKU4GhMeta } from "/root/front/pages/influencer/list/popular.vue?macro=true";
import { default as _91_46_46_46slug_93RwpvU4xzKEMeta } from "/root/front/pages/influencer/list/tags/[...slug].vue?macro=true";
import { default as parameterxmqjazwQFIMeta } from "/root/front/pages/influencer/parameter.vue?macro=true";
import { default as editIWiskBF4kGMeta } from "/root/front/pages/influencer/profile/[slug]/edit.vue?macro=true";
import { default as index2WRwFhZZSLMeta } from "/root/front/pages/influencer/profile/[slug]/index.vue?macro=true";
import { default as indexkWuHnlKNhzMeta } from "/root/front/pages/influencer/profile/index.vue?macro=true";
import { default as discoveryfGE4Oj7fNxMeta } from "/root/front/pages/influencer/signup/discovery.vue?macro=true";
import { default as indexXZtYyuwedLMeta } from "/root/front/pages/influencer/signup/index.vue?macro=true";
import { default as bioUyHCCsBWTgMeta } from "/root/front/pages/influencer/signup/v2/bio.vue?macro=true";
import { default as genreskpWSMDOngPMeta } from "/root/front/pages/influencer/signup/v2/genres.vue?macro=true";
import { default as lang_45lyrics_45releasehv4xqoQVm0Meta } from "/root/front/pages/influencer/signup/v2/lang-lyrics-release.vue?macro=true";
import { default as name_45countryuYbGVBGhlNMeta } from "/root/front/pages/influencer/signup/v2/name-country.vue?macro=true";
import { default as pictureqCbTcxl8JFMeta } from "/root/front/pages/influencer/signup/v2/picture.vue?macro=true";
import { default as servicesHoRczAgNIcMeta } from "/root/front/pages/influencer/signup/v2/services.vue?macro=true";
import { default as similar_45artistsf3NkRSEKPAMeta } from "/root/front/pages/influencer/signup/v2/similar-artists.vue?macro=true";
import { default as typefUVyV1H9yDMeta } from "/root/front/pages/influencer/signup/v2/type.vue?macro=true";
import { default as website_45linksVKP4oURc4eMeta } from "/root/front/pages/influencer/signup/v2/website-links.vue?macro=true";
import { default as indexrMObRirAUiMeta } from "/root/front/pages/influencer/wallet/index.vue?macro=true";
import { default as transfertc93gclRvXMeta } from "/root/front/pages/influencer/wallet/transfer.vue?macro=true";
import { default as withdraw2btdo9fqu0Meta } from "/root/front/pages/influencer/wallet/withdraw.vue?macro=true";
import { default as walletzyZvnk6DgNMeta } from "/root/front/pages/influencer/wallet.vue?macro=true";
import { default as _91slug_93Hajv2S5MHPMeta } from "/root/front/pages/influencer/widget/[slug].vue?macro=true";
import { default as indexWaqoQ0uhHxMeta } from "/root/front/pages/influencer/widget/index.vue?macro=true";
import { default as _91slug_9326X1Ll7L2QMeta } from "/root/front/pages/influencer/widget/redirect/[slug].vue?macro=true";
import { default as partners48ThJ6eswlMeta } from "/root/front/pages/partners.vue?macro=true";
import { default as failure1Lx2Eim0hwMeta } from "/root/front/pages/payin/failure.vue?macro=true";
import { default as retryVf0ZNiB91uMeta } from "/root/front/pages/payin/paypal/retry.vue?macro=true";
import { default as pridezKK14LRwtcMeta } from "/root/front/pages/pride.vue?macro=true";
import { default as purgejrU4pe1MPSMeta } from "/root/front/pages/purge.vue?macro=true";
import { default as reset_45passwordup3j5I624DMeta } from "/root/front/pages/reset-password.vue?macro=true";
import { default as signuppt6CNYSyZ8Meta } from "/root/front/pages/signup.vue?macro=true";
import { default as indexN4PIA2I2q1Meta } from "/root/front/pages/spotify/playlist/[id]/index.vue?macro=true";
import { default as loadingwYdgheCmQ0Meta } from "/root/front/pages/spotify/playlist/[id]/loading.vue?macro=true";
import { default as trackLwiFtKkZOUMeta } from "/root/front/pages/spotify/share/track.vue?macro=true";
import { default as test3ADM2ezGWaMeta } from "/root/front/pages/test.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: {"middleware":"index-redirection","layout":false},
    component: () => import("/root/front/pages/lp/[slug].vue")
  },
  {
    name: "index___en",
    path: "/en/",
    meta: {"middleware":"index-redirection","layout":false},
    component: () => import("/root/front/pages/lp/[slug].vue")
  },
  {
    name: "index___fr",
    path: "/fr/",
    meta: {"middleware":"index-redirection","layout":false},
    component: () => import("/root/front/pages/lp/[slug].vue")
  },
  {
    name: "index___de",
    path: "/de/",
    meta: {"middleware":"index-redirection","layout":false},
    component: () => import("/root/front/pages/lp/[slug].vue")
  },
  {
    name: "about___en",
    path: "/en/about/",
    component: () => import("/root/front/pages/about.vue")
  },
  {
    name: "about___fr",
    path: "/fr/about/",
    component: () => import("/root/front/pages/about.vue")
  },
  {
    name: "about___de",
    path: "/de/about/",
    component: () => import("/root/front/pages/about.vue")
  },
  {
    name: "agency-favorites-id___en",
    path: "/en/agency/favorites/:id()/",
    meta: _91id_93iMYJBkgu4BMeta || {},
    component: () => import("/root/front/pages/agency/favorites/[id].vue")
  },
  {
    name: "agency-favorites-id___fr",
    path: "/fr/agency/favorites/:id()/",
    meta: _91id_93iMYJBkgu4BMeta || {},
    component: () => import("/root/front/pages/agency/favorites/[id].vue")
  },
  {
    name: "agency-favorites-id___de",
    path: "/de/agency/favorites/:id()/",
    meta: _91id_93iMYJBkgu4BMeta || {},
    component: () => import("/root/front/pages/agency/favorites/[id].vue")
  },
  {
    name: "agency-favorites___en",
    path: "/en/agency/favorites/",
    meta: indexpNPojHfcp7Meta || {},
    component: () => import("/root/front/pages/agency/favorites/index.vue")
  },
  {
    name: "agency-favorites___fr",
    path: "/fr/agency/favorites/",
    meta: indexpNPojHfcp7Meta || {},
    component: () => import("/root/front/pages/agency/favorites/index.vue")
  },
  {
    name: "agency-favorites___de",
    path: "/de/agency/favorites/",
    meta: indexpNPojHfcp7Meta || {},
    component: () => import("/root/front/pages/agency/favorites/index.vue")
  },
  {
    name: "band-create-draft___en",
    path: "/en/band/create-draft/",
    meta: create_45draftpKoo2f8BlgMeta || {},
    component: () => import("/root/front/pages/band/create-draft.vue")
  },
  {
    name: "band-create-draft___fr",
    path: "/fr/band/create-draft/",
    meta: create_45draftpKoo2f8BlgMeta || {},
    component: () => import("/root/front/pages/band/create-draft.vue")
  },
  {
    name: "band-create-draft___de",
    path: "/de/band/create-draft/",
    meta: create_45draftpKoo2f8BlgMeta || {},
    component: () => import("/root/front/pages/band/create-draft.vue")
  },
  {
    name: dashboardQ0wsN6fQU1Meta?.name,
    path: "/en/band/dashboard/",
    meta: dashboardQ0wsN6fQU1Meta || {},
    component: () => import("/root/front/pages/band/dashboard.vue"),
    children: [
  {
    name: "band-dashboard-emails___en",
    path: "emails/",
    component: () => import("/root/front/pages/band/dashboard/emails.vue")
  },
  {
    name: "band-dashboard___en",
    path: "",
    component: () => import("/root/front/pages/band/dashboard/index.vue")
  }
]
  },
  {
    name: dashboardQ0wsN6fQU1Meta?.name,
    path: "/fr/band/dashboard/",
    meta: dashboardQ0wsN6fQU1Meta || {},
    component: () => import("/root/front/pages/band/dashboard.vue"),
    children: [
  {
    name: "band-dashboard-emails___fr",
    path: "emails/",
    component: () => import("/root/front/pages/band/dashboard/emails.vue")
  },
  {
    name: "band-dashboard___fr",
    path: "",
    component: () => import("/root/front/pages/band/dashboard/index.vue")
  }
]
  },
  {
    name: dashboardQ0wsN6fQU1Meta?.name,
    path: "/de/band/dashboard/",
    meta: dashboardQ0wsN6fQU1Meta || {},
    component: () => import("/root/front/pages/band/dashboard.vue"),
    children: [
  {
    name: "band-dashboard-emails___de",
    path: "emails/",
    component: () => import("/root/front/pages/band/dashboard/emails.vue")
  },
  {
    name: "band-dashboard___de",
    path: "",
    component: () => import("/root/front/pages/band/dashboard/index.vue")
  }
]
  },
  {
    name: "band-edit-track-id-campaign-campaignId___en",
    path: "/en/band/edit/track/:id()/campaign/:campaignId()/",
    meta: indexkJBxZ5jReMMeta || {},
    component: () => import("/root/front/pages/band/edit/track/[id]/campaign/[campaignId]/index.vue")
  },
  {
    name: "band-edit-track-id-campaign-campaignId___fr",
    path: "/fr/band/edit/track/:id()/campaign/:campaignId()/",
    meta: indexkJBxZ5jReMMeta || {},
    component: () => import("/root/front/pages/band/edit/track/[id]/campaign/[campaignId]/index.vue")
  },
  {
    name: "band-edit-track-id-campaign-campaignId___de",
    path: "/de/band/edit/track/:id()/campaign/:campaignId()/",
    meta: indexkJBxZ5jReMMeta || {},
    component: () => import("/root/front/pages/band/edit/track/[id]/campaign/[campaignId]/index.vue")
  },
  {
    name: "band-edit-track-id___en",
    path: "/en/band/edit/track/:id()/",
    meta: indexhhHcmvdak8Meta || {},
    component: () => import("/root/front/pages/band/edit/track/[id]/index.vue")
  },
  {
    name: "band-edit-track-id___fr",
    path: "/fr/band/edit/track/:id()/",
    meta: indexhhHcmvdak8Meta || {},
    component: () => import("/root/front/pages/band/edit/track/[id]/index.vue")
  },
  {
    name: "band-edit-track-id___de",
    path: "/de/band/edit/track/:id()/",
    meta: indexhhHcmvdak8Meta || {},
    component: () => import("/root/front/pages/band/edit/track/[id]/index.vue")
  },
  {
    name: "band-homepage___en",
    path: "/en/band/homepage/",
    meta: homepageYpHVGeyLjwMeta || {},
    component: () => import("/root/front/pages/band/homepage.vue")
  },
  {
    name: "band-homepage___fr",
    path: "/fr/band/homepage/",
    meta: homepageYpHVGeyLjwMeta || {},
    component: () => import("/root/front/pages/band/homepage.vue")
  },
  {
    name: "band-homepage___de",
    path: "/de/band/homepage/",
    meta: homepageYpHVGeyLjwMeta || {},
    component: () => import("/root/front/pages/band/homepage.vue")
  },
  {
    name: "band-onboarding___en",
    path: "/en/band/onboarding/",
    meta: onboarding9PTI0SNpbjMeta || {},
    component: () => import("/root/front/pages/band/onboarding.vue")
  },
  {
    name: "band-onboarding___fr",
    path: "/fr/band/onboarding/",
    meta: onboarding9PTI0SNpbjMeta || {},
    component: () => import("/root/front/pages/band/onboarding.vue")
  },
  {
    name: "band-onboarding___de",
    path: "/de/band/onboarding/",
    meta: onboarding9PTI0SNpbjMeta || {},
    component: () => import("/root/front/pages/band/onboarding.vue")
  },
  {
    name: "band-parameter___en",
    path: "/en/band/parameter/",
    meta: parameterQVjqIQIu4jMeta || {},
    component: () => import("/root/front/pages/band/parameter.vue")
  },
  {
    name: "band-parameter___fr",
    path: "/fr/band/parameter/",
    meta: parameterQVjqIQIu4jMeta || {},
    component: () => import("/root/front/pages/band/parameter.vue")
  },
  {
    name: "band-parameter___de",
    path: "/de/band/parameter/",
    meta: parameterQVjqIQIu4jMeta || {},
    component: () => import("/root/front/pages/band/parameter.vue")
  },
  {
    name: "band-payin___en",
    path: "/en/band/payin/",
    meta: payinInHdtomh3jMeta || {},
    component: () => import("/root/front/pages/band/payin.vue")
  },
  {
    name: "band-payin___fr",
    path: "/fr/band/payin/",
    meta: payinInHdtomh3jMeta || {},
    component: () => import("/root/front/pages/band/payin.vue")
  },
  {
    name: "band-payin___de",
    path: "/de/band/payin/",
    meta: payinInHdtomh3jMeta || {},
    component: () => import("/root/front/pages/band/payin.vue")
  },
  {
    name: "band-profile-slug-edit___en",
    path: "/en/band/profile/:slug()/edit/",
    meta: editPqVz7WjV8lMeta || {},
    component: () => import("/root/front/pages/band/profile/[slug]/edit.vue")
  },
  {
    name: "band-profile-slug-edit___fr",
    path: "/fr/band/profile/:slug()/edit/",
    meta: editPqVz7WjV8lMeta || {},
    component: () => import("/root/front/pages/band/profile/[slug]/edit.vue")
  },
  {
    name: "band-profile-slug-edit___de",
    path: "/de/band/profile/:slug()/edit/",
    meta: editPqVz7WjV8lMeta || {},
    component: () => import("/root/front/pages/band/profile/[slug]/edit.vue")
  },
  {
    name: "band-profile-slug___en",
    path: "/en/band/profile/:slug()/",
    component: () => import("/root/front/pages/band/profile/[slug]/index.vue")
  },
  {
    name: "band-profile-slug___fr",
    path: "/fr/band/profile/:slug()/",
    component: () => import("/root/front/pages/band/profile/[slug]/index.vue")
  },
  {
    name: "band-profile-slug___de",
    path: "/de/band/profile/:slug()/",
    component: () => import("/root/front/pages/band/profile/[slug]/index.vue")
  },
  {
    name: "band-profile-create___en",
    path: "/en/band/profile/create/",
    meta: create0Bd0WsPFoLMeta || {},
    component: () => import("/root/front/pages/band/profile/create.vue")
  },
  {
    name: "band-profile-create___fr",
    path: "/fr/band/profile/create/",
    meta: create0Bd0WsPFoLMeta || {},
    component: () => import("/root/front/pages/band/profile/create.vue")
  },
  {
    name: "band-profile-create___de",
    path: "/de/band/profile/create/",
    meta: create0Bd0WsPFoLMeta || {},
    component: () => import("/root/front/pages/band/profile/create.vue")
  },
  {
    name: "band-profile-delete___en",
    path: "/en/band/profile/delete/",
    meta: deleteaRA1pcvzRJMeta || {},
    component: () => import("/root/front/pages/band/profile/delete.vue")
  },
  {
    name: "band-profile-delete___fr",
    path: "/fr/band/profile/delete/",
    meta: deleteaRA1pcvzRJMeta || {},
    component: () => import("/root/front/pages/band/profile/delete.vue")
  },
  {
    name: "band-profile-delete___de",
    path: "/de/band/profile/delete/",
    meta: deleteaRA1pcvzRJMeta || {},
    component: () => import("/root/front/pages/band/profile/delete.vue")
  },
  {
    name: "band-profile___en",
    path: "/en/band/profile/",
    meta: indexG9hLdub9L3Meta || {},
    component: () => import("/root/front/pages/band/profile/index.vue")
  },
  {
    name: "band-profile___fr",
    path: "/fr/band/profile/",
    meta: indexG9hLdub9L3Meta || {},
    component: () => import("/root/front/pages/band/profile/index.vue")
  },
  {
    name: "band-profile___de",
    path: "/de/band/profile/",
    meta: indexG9hLdub9L3Meta || {},
    component: () => import("/root/front/pages/band/profile/index.vue")
  },
  {
    name: "band-profile-select___en",
    path: "/en/band/profile/select/",
    meta: selectMJYWvPJonnMeta || {},
    component: () => import("/root/front/pages/band/profile/select.vue")
  },
  {
    name: "band-profile-select___fr",
    path: "/fr/band/profile/select/",
    meta: selectMJYWvPJonnMeta || {},
    component: () => import("/root/front/pages/band/profile/select.vue")
  },
  {
    name: "band-profile-select___de",
    path: "/de/band/profile/select/",
    meta: selectMJYWvPJonnMeta || {},
    component: () => import("/root/front/pages/band/profile/select.vue")
  },
  {
    name: "band-referral-code___en",
    path: "/en/band/referral/:code()/",
    meta: _91code_93tmy2kpqnPRMeta || {},
    component: () => import("/root/front/pages/band/referral/[code].vue")
  },
  {
    name: "band-referral-code___fr",
    path: "/fr/band/referral/:code()/",
    meta: _91code_93tmy2kpqnPRMeta || {},
    component: () => import("/root/front/pages/band/referral/[code].vue")
  },
  {
    name: "band-referral-code___de",
    path: "/de/band/referral/:code()/",
    meta: _91code_93tmy2kpqnPRMeta || {},
    component: () => import("/root/front/pages/band/referral/[code].vue")
  },
  {
    name: "band-referral___en",
    path: "/en/band/referral/",
    meta: indexT58W6sSCY9Meta || {},
    component: () => import("/root/front/pages/band/referral/index.vue")
  },
  {
    name: "band-referral___fr",
    path: "/fr/band/referral/",
    meta: indexT58W6sSCY9Meta || {},
    component: () => import("/root/front/pages/band/referral/index.vue")
  },
  {
    name: "band-referral___de",
    path: "/de/band/referral/",
    meta: indexT58W6sSCY9Meta || {},
    component: () => import("/root/front/pages/band/referral/index.vue")
  },
  {
    name: "band-sendtrack___en",
    path: "/en/band/sendtrack/",
    meta: sendtrack5lizRzTiYFMeta || {},
    component: () => import("/root/front/pages/band/sendtrack.vue")
  },
  {
    name: "band-sendtrack___fr",
    path: "/fr/band/sendtrack/",
    meta: sendtrack5lizRzTiYFMeta || {},
    component: () => import("/root/front/pages/band/sendtrack.vue")
  },
  {
    name: "band-sendtrack___de",
    path: "/de/band/sendtrack/",
    meta: sendtrack5lizRzTiYFMeta || {},
    component: () => import("/root/front/pages/band/sendtrack.vue")
  },
  {
    name: "band-shop-finalized___en",
    path: "/en/band/shop/finalized/",
    meta: finalizedOMzXYtNZ4XMeta || {},
    component: () => import("/root/front/pages/band/shop/finalized.vue")
  },
  {
    name: "band-shop-finalized___fr",
    path: "/fr/band/shop/finalized/",
    meta: finalizedOMzXYtNZ4XMeta || {},
    component: () => import("/root/front/pages/band/shop/finalized.vue")
  },
  {
    name: "band-shop-finalized___de",
    path: "/de/band/shop/finalized/",
    meta: finalizedOMzXYtNZ4XMeta || {},
    component: () => import("/root/front/pages/band/shop/finalized.vue")
  },
  {
    name: "band-shop___en",
    path: "/en/band/shop/",
    meta: indexvTZo9RqsMrMeta || {},
    component: () => import("/root/front/pages/band/shop/index.vue")
  },
  {
    name: "band-shop___fr",
    path: "/fr/band/shop/",
    meta: indexvTZo9RqsMrMeta || {},
    component: () => import("/root/front/pages/band/shop/index.vue")
  },
  {
    name: "band-shop___de",
    path: "/de/band/shop/",
    meta: indexvTZo9RqsMrMeta || {},
    component: () => import("/root/front/pages/band/shop/index.vue")
  },
  {
    name: "band-shop-processing-stripe___en",
    path: "/en/band/shop/processing/stripe/",
    component: () => import("/root/front/pages/band/shop/processing/stripe.vue")
  },
  {
    name: "band-shop-processing-stripe___fr",
    path: "/fr/band/shop/processing/stripe/",
    component: () => import("/root/front/pages/band/shop/processing/stripe.vue")
  },
  {
    name: "band-shop-processing-stripe___de",
    path: "/de/band/shop/processing/stripe/",
    component: () => import("/root/front/pages/band/shop/processing/stripe.vue")
  },
  {
    name: "band-signup-discovery___en",
    path: "/en/band/signup/discovery/",
    component: () => import("/root/front/pages/band/signup/discovery.vue")
  },
  {
    name: "band-signup-discovery___fr",
    path: "/fr/band/signup/discovery/",
    component: () => import("/root/front/pages/band/signup/discovery.vue")
  },
  {
    name: "band-signup-discovery___de",
    path: "/de/band/signup/discovery/",
    component: () => import("/root/front/pages/band/signup/discovery.vue")
  },
  {
    name: "band-signup___en",
    path: "/en/band/signup/",
    meta: indexSeO6A7IHADMeta || {},
    component: () => import("/root/front/pages/band/signup/index.vue")
  },
  {
    name: "band-signup___fr",
    path: "/fr/band/signup/",
    meta: indexSeO6A7IHADMeta || {},
    component: () => import("/root/front/pages/band/signup/index.vue")
  },
  {
    name: "band-signup___de",
    path: "/de/band/signup/",
    meta: indexSeO6A7IHADMeta || {},
    component: () => import("/root/front/pages/band/signup/index.vue")
  },
  {
    name: "band-signup-referral-agency-code___en",
    path: "/en/band/signup/referral/agency/:code()/",
    meta: _91code_93RlPi5TLtnpMeta || {},
    component: () => import("/root/front/pages/band/signup/referral/agency/[code].vue")
  },
  {
    name: "band-signup-referral-agency-code___fr",
    path: "/fr/band/signup/referral/agency/:code()/",
    meta: _91code_93RlPi5TLtnpMeta || {},
    component: () => import("/root/front/pages/band/signup/referral/agency/[code].vue")
  },
  {
    name: "band-signup-referral-agency-code___de",
    path: "/de/band/signup/referral/agency/:code()/",
    meta: _91code_93RlPi5TLtnpMeta || {},
    component: () => import("/root/front/pages/band/signup/referral/agency/[code].vue")
  },
  {
    name: "band-signup-referral-influencer-code___en",
    path: "/en/band/signup/referral/influencer/:code()/",
    component: () => import("/root/front/pages/band/signup/referral/influencer/[code].vue")
  },
  {
    name: "band-signup-referral-influencer-code___fr",
    path: "/fr/band/signup/referral/influencer/:code()/",
    component: () => import("/root/front/pages/band/signup/referral/influencer/[code].vue")
  },
  {
    name: "band-signup-referral-influencer-code___de",
    path: "/de/band/signup/referral/influencer/:code()/",
    component: () => import("/root/front/pages/band/signup/referral/influencer/[code].vue")
  },
  {
    name: "cgs___en",
    path: "/en/cgs/",
    component: () => import("/root/front/pages/cgs.vue")
  },
  {
    name: "cgs___fr",
    path: "/fr/cgs/",
    component: () => import("/root/front/pages/cgs.vue")
  },
  {
    name: "cgs___de",
    path: "/de/cgs/",
    component: () => import("/root/front/pages/cgs.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact/",
    component: () => import("/root/front/pages/contact.vue")
  },
  {
    name: "contact___fr",
    path: "/fr/contact/",
    component: () => import("/root/front/pages/contact.vue")
  },
  {
    name: "contact___de",
    path: "/de/contact/",
    component: () => import("/root/front/pages/contact.vue")
  },
  {
    name: "draft-id-finalized___en",
    path: "/en/draft/:id()/finalized/",
    component: () => import("/root/front/pages/draft/[id]/finalized.vue")
  },
  {
    name: "draft-id-finalized___fr",
    path: "/fr/draft/:id()/finalized/",
    component: () => import("/root/front/pages/draft/[id]/finalized.vue")
  },
  {
    name: "draft-id-finalized___de",
    path: "/de/draft/:id()/finalized/",
    component: () => import("/root/front/pages/draft/[id]/finalized.vue")
  },
  {
    name: "draft-id___en",
    path: "/en/draft/:id()/",
    meta: indexhR1daect7xMeta || {},
    component: () => import("/root/front/pages/draft/[id]/index.vue"),
    children: [
  {
    name: "draft-id-index-messages___en",
    path: "messages/",
    component: () => import("/root/front/pages/draft/[id]/index/messages.vue")
  },
  {
    name: "draft-id-index-recap___en",
    path: "recap/",
    meta: recap0TB2JcmMdfMeta || {},
    component: () => import("/root/front/pages/draft/[id]/index/recap.vue")
  },
  {
    name: "draft-id-index-shared-music___en",
    path: "shared-music/",
    meta: shared_45musicT1OdrRmn64Meta || {},
    component: () => import("/root/front/pages/draft/[id]/index/shared-music.vue")
  },
  {
    name: "draft-id-index-track___en",
    path: "track/",
    component: () => import("/root/front/pages/draft/[id]/index/track.vue")
  }
]
  },
  {
    name: "draft-id___fr",
    path: "/fr/draft/:id()/",
    meta: indexhR1daect7xMeta || {},
    component: () => import("/root/front/pages/draft/[id]/index.vue"),
    children: [
  {
    name: "draft-id-index-messages___fr",
    path: "messages/",
    component: () => import("/root/front/pages/draft/[id]/index/messages.vue")
  },
  {
    name: "draft-id-index-recap___fr",
    path: "recap/",
    meta: recap0TB2JcmMdfMeta || {},
    component: () => import("/root/front/pages/draft/[id]/index/recap.vue")
  },
  {
    name: "draft-id-index-shared-music___fr",
    path: "shared-music/",
    meta: shared_45musicT1OdrRmn64Meta || {},
    component: () => import("/root/front/pages/draft/[id]/index/shared-music.vue")
  },
  {
    name: "draft-id-index-track___fr",
    path: "track/",
    component: () => import("/root/front/pages/draft/[id]/index/track.vue")
  }
]
  },
  {
    name: "draft-id___de",
    path: "/de/draft/:id()/",
    meta: indexhR1daect7xMeta || {},
    component: () => import("/root/front/pages/draft/[id]/index.vue"),
    children: [
  {
    name: "draft-id-index-messages___de",
    path: "messages/",
    component: () => import("/root/front/pages/draft/[id]/index/messages.vue")
  },
  {
    name: "draft-id-index-recap___de",
    path: "recap/",
    meta: recap0TB2JcmMdfMeta || {},
    component: () => import("/root/front/pages/draft/[id]/index/recap.vue")
  },
  {
    name: "draft-id-index-shared-music___de",
    path: "shared-music/",
    meta: shared_45musicT1OdrRmn64Meta || {},
    component: () => import("/root/front/pages/draft/[id]/index/shared-music.vue")
  },
  {
    name: "draft-id-index-track___de",
    path: "track/",
    component: () => import("/root/front/pages/draft/[id]/index/track.vue")
  }
]
  },
  {
    name: influencersZDBqKdhyGNMeta?.name,
    path: "/en/draft/:id()/influencers/",
    component: () => import("/root/front/pages/draft/[id]/influencers.vue"),
    children: [
  {
    name: "draft-id-influencers___en",
    path: "",
    meta: indexlESNhhasq4Meta || {},
    component: () => import("/root/front/pages/draft/[id]/influencers/index.vue")
  },
  {
    name: "draft-id-influencers-lists___en",
    path: "lists/",
    meta: listsM5hwW2O7EhMeta || {},
    component: () => import("/root/front/pages/draft/[id]/influencers/lists.vue")
  },
  {
    name: "draft-id-influencers-loading___en",
    path: "loading/",
    component: () => import("/root/front/pages/draft/[id]/influencers/loading.vue")
  }
]
  },
  {
    name: influencersZDBqKdhyGNMeta?.name,
    path: "/fr/draft/:id()/influencers/",
    component: () => import("/root/front/pages/draft/[id]/influencers.vue"),
    children: [
  {
    name: "draft-id-influencers___fr",
    path: "",
    meta: indexlESNhhasq4Meta || {},
    component: () => import("/root/front/pages/draft/[id]/influencers/index.vue")
  },
  {
    name: "draft-id-influencers-lists___fr",
    path: "lists/",
    meta: listsM5hwW2O7EhMeta || {},
    component: () => import("/root/front/pages/draft/[id]/influencers/lists.vue")
  },
  {
    name: "draft-id-influencers-loading___fr",
    path: "loading/",
    component: () => import("/root/front/pages/draft/[id]/influencers/loading.vue")
  }
]
  },
  {
    name: influencersZDBqKdhyGNMeta?.name,
    path: "/de/draft/:id()/influencers/",
    component: () => import("/root/front/pages/draft/[id]/influencers.vue"),
    children: [
  {
    name: "draft-id-influencers___de",
    path: "",
    meta: indexlESNhhasq4Meta || {},
    component: () => import("/root/front/pages/draft/[id]/influencers/index.vue")
  },
  {
    name: "draft-id-influencers-lists___de",
    path: "lists/",
    meta: listsM5hwW2O7EhMeta || {},
    component: () => import("/root/front/pages/draft/[id]/influencers/lists.vue")
  },
  {
    name: "draft-id-influencers-loading___de",
    path: "loading/",
    component: () => import("/root/front/pages/draft/[id]/influencers/loading.vue")
  }
]
  },
  {
    name: "draft-id-post3ds___en",
    path: "/en/draft/:id()/post3ds/",
    meta: post3dsqsGOnHS7y8Meta || {},
    component: () => import("/root/front/pages/draft/[id]/post3ds.vue")
  },
  {
    name: "draft-id-post3ds___fr",
    path: "/fr/draft/:id()/post3ds/",
    meta: post3dsqsGOnHS7y8Meta || {},
    component: () => import("/root/front/pages/draft/[id]/post3ds.vue")
  },
  {
    name: "draft-id-post3ds___de",
    path: "/de/draft/:id()/post3ds/",
    meta: post3dsqsGOnHS7y8Meta || {},
    component: () => import("/root/front/pages/draft/[id]/post3ds.vue")
  },
  {
    name: "draft-id-processing-stripe___en",
    path: "/en/draft/:id()/processing/stripe/",
    component: () => import("/root/front/pages/draft/[id]/processing/stripe.vue")
  },
  {
    name: "draft-id-processing-stripe___fr",
    path: "/fr/draft/:id()/processing/stripe/",
    component: () => import("/root/front/pages/draft/[id]/processing/stripe.vue")
  },
  {
    name: "draft-id-processing-stripe___de",
    path: "/de/draft/:id()/processing/stripe/",
    component: () => import("/root/front/pages/draft/[id]/processing/stripe.vue")
  },
  {
    name: "draft___en",
    path: "/en/draft/",
    meta: indexLPiTjv4We6Meta || {},
    component: () => import("/root/front/pages/draft/index.vue")
  },
  {
    name: "draft___fr",
    path: "/fr/draft/",
    meta: indexLPiTjv4We6Meta || {},
    component: () => import("/root/front/pages/draft/index.vue")
  },
  {
    name: "draft___de",
    path: "/de/draft/",
    meta: indexLPiTjv4We6Meta || {},
    component: () => import("/root/front/pages/draft/index.vue")
  },
  {
    name: "draft-influencers___en",
    path: "/en/draft/influencers/",
    meta: influencersES0i7yNr4dMeta || {},
    component: () => import("/root/front/pages/draft/influencers.vue")
  },
  {
    name: "draft-influencers___fr",
    path: "/fr/draft/influencers/",
    meta: influencersES0i7yNr4dMeta || {},
    component: () => import("/root/front/pages/draft/influencers.vue")
  },
  {
    name: "draft-influencers___de",
    path: "/de/draft/influencers/",
    meta: influencersES0i7yNr4dMeta || {},
    component: () => import("/root/front/pages/draft/influencers.vue")
  },
  {
    name: "finalize-auth___en",
    path: "/en/finalize-auth/",
    meta: finalize_45authES9ZboEb3UMeta || {},
    component: () => import("/root/front/pages/finalize-auth.vue")
  },
  {
    name: "finalize-auth___fr",
    path: "/fr/finalize-auth/",
    meta: finalize_45authES9ZboEb3UMeta || {},
    component: () => import("/root/front/pages/finalize-auth.vue")
  },
  {
    name: "finalize-auth___de",
    path: "/de/finalize-auth/",
    meta: finalize_45authES9ZboEb3UMeta || {},
    component: () => import("/root/front/pages/finalize-auth.vue")
  },
  {
    name: "influencer-activate___en",
    path: "/en/influencer/activate/",
    meta: activateLuTEMJoA7FMeta || {},
    component: () => import("/root/front/pages/influencer/activate.vue")
  },
  {
    name: "influencer-activate___fr",
    path: "/fr/influencer/activate/",
    meta: activateLuTEMJoA7FMeta || {},
    component: () => import("/root/front/pages/influencer/activate.vue")
  },
  {
    name: "influencer-activate___de",
    path: "/de/influencer/activate/",
    meta: activateLuTEMJoA7FMeta || {},
    component: () => import("/root/front/pages/influencer/activate.vue")
  },
  {
    name: "influencer-cashout___en",
    path: "/en/influencer/cashout/",
    meta: cashoutzdSv1RjprAMeta || {},
    component: () => import("/root/front/pages/influencer/cashout.vue")
  },
  {
    name: "influencer-cashout___fr",
    path: "/fr/influencer/cashout/",
    meta: cashoutzdSv1RjprAMeta || {},
    component: () => import("/root/front/pages/influencer/cashout.vue")
  },
  {
    name: "influencer-cashout___de",
    path: "/de/influencer/cashout/",
    meta: cashoutzdSv1RjprAMeta || {},
    component: () => import("/root/front/pages/influencer/cashout.vue")
  },
  {
    name: "influencer-dashboard___en",
    path: "/en/influencer/dashboard/",
    meta: dashboardwaqr7P28zFMeta || {},
    component: () => import("/root/front/pages/influencer/dashboard.vue")
  },
  {
    name: "influencer-dashboard___fr",
    path: "/fr/influencer/dashboard/",
    meta: dashboardwaqr7P28zFMeta || {},
    component: () => import("/root/front/pages/influencer/dashboard.vue")
  },
  {
    name: "influencer-dashboard___de",
    path: "/de/influencer/dashboard/",
    meta: dashboardwaqr7P28zFMeta || {},
    component: () => import("/root/front/pages/influencer/dashboard.vue")
  },
  {
    name: "influencer-list-all___en",
    path: "/en/influencer/list/all/",
    meta: index6NaBTKhdUnMeta || {},
    component: () => import("/root/front/pages/influencer/list/all/index.vue")
  },
  {
    name: "influencer-list-all___fr",
    path: "/fr/influencer/list/all/",
    meta: index6NaBTKhdUnMeta || {},
    component: () => import("/root/front/pages/influencer/list/all/index.vue")
  },
  {
    name: "influencer-list-all___de",
    path: "/de/influencer/list/all/",
    meta: index6NaBTKhdUnMeta || {},
    component: () => import("/root/front/pages/influencer/list/all/index.vue")
  },
  {
    name: "influencer-list-fans___en",
    path: "/en/influencer/list/fans/",
    meta: fansw15KVqFUBmMeta || {},
    component: () => import("/root/front/pages/influencer/list/fans.vue")
  },
  {
    name: "influencer-list-fans___fr",
    path: "/fr/influencer/list/fans/",
    meta: fansw15KVqFUBmMeta || {},
    component: () => import("/root/front/pages/influencer/list/fans.vue")
  },
  {
    name: "influencer-list-fans___de",
    path: "/de/influencer/list/fans/",
    meta: fansw15KVqFUBmMeta || {},
    component: () => import("/root/front/pages/influencer/list/fans.vue")
  },
  {
    name: "influencer-list-new-arrivals___en",
    path: "/en/influencer/list/new-arrivals/",
    meta: new_45arrivalsbtJH3dXygAMeta || {},
    component: () => import("/root/front/pages/influencer/list/new-arrivals.vue")
  },
  {
    name: "influencer-list-new-arrivals___fr",
    path: "/fr/influencer/list/new-arrivals/",
    meta: new_45arrivalsbtJH3dXygAMeta || {},
    component: () => import("/root/front/pages/influencer/list/new-arrivals.vue")
  },
  {
    name: "influencer-list-new-arrivals___de",
    path: "/de/influencer/list/new-arrivals/",
    meta: new_45arrivalsbtJH3dXygAMeta || {},
    component: () => import("/root/front/pages/influencer/list/new-arrivals.vue")
  },
  {
    name: "influencer-list-popular___en",
    path: "/en/influencer/list/popular/",
    meta: popular1lP3JKU4GhMeta || {},
    component: () => import("/root/front/pages/influencer/list/popular.vue")
  },
  {
    name: "influencer-list-popular___fr",
    path: "/fr/influencer/list/popular/",
    meta: popular1lP3JKU4GhMeta || {},
    component: () => import("/root/front/pages/influencer/list/popular.vue")
  },
  {
    name: "influencer-list-popular___de",
    path: "/de/influencer/list/popular/",
    meta: popular1lP3JKU4GhMeta || {},
    component: () => import("/root/front/pages/influencer/list/popular.vue")
  },
  {
    name: "influencer-list-tags-slug___en",
    path: "/en/influencer/list/tags/:slug(.*)*/",
    meta: _91_46_46_46slug_93RwpvU4xzKEMeta || {},
    component: () => import("/root/front/pages/influencer/list/tags/[...slug].vue")
  },
  {
    name: "influencer-list-tags-slug___fr",
    path: "/fr/influencer/list/tags/:slug(.*)*/",
    meta: _91_46_46_46slug_93RwpvU4xzKEMeta || {},
    component: () => import("/root/front/pages/influencer/list/tags/[...slug].vue")
  },
  {
    name: "influencer-list-tags-slug___de",
    path: "/de/influencer/list/tags/:slug(.*)*/",
    meta: _91_46_46_46slug_93RwpvU4xzKEMeta || {},
    component: () => import("/root/front/pages/influencer/list/tags/[...slug].vue")
  },
  {
    name: "influencer-parameter___en",
    path: "/en/influencer/parameter/",
    meta: parameterxmqjazwQFIMeta || {},
    component: () => import("/root/front/pages/influencer/parameter.vue")
  },
  {
    name: "influencer-parameter___fr",
    path: "/fr/influencer/parameter/",
    meta: parameterxmqjazwQFIMeta || {},
    component: () => import("/root/front/pages/influencer/parameter.vue")
  },
  {
    name: "influencer-parameter___de",
    path: "/de/influencer/parameter/",
    meta: parameterxmqjazwQFIMeta || {},
    component: () => import("/root/front/pages/influencer/parameter.vue")
  },
  {
    name: "influencer-profile-slug-edit___en",
    path: "/en/influencer/profile/:slug()/edit/",
    meta: editIWiskBF4kGMeta || {},
    component: () => import("/root/front/pages/influencer/profile/[slug]/edit.vue")
  },
  {
    name: "influencer-profile-slug-edit___fr",
    path: "/fr/influencer/profile/:slug()/edit/",
    meta: editIWiskBF4kGMeta || {},
    component: () => import("/root/front/pages/influencer/profile/[slug]/edit.vue")
  },
  {
    name: "influencer-profile-slug-edit___de",
    path: "/de/influencer/profile/:slug()/edit/",
    meta: editIWiskBF4kGMeta || {},
    component: () => import("/root/front/pages/influencer/profile/[slug]/edit.vue")
  },
  {
    name: "influencer-profile-slug___en",
    path: "/en/influencer/profile/:slug()/",
    meta: index2WRwFhZZSLMeta || {},
    component: () => import("/root/front/pages/influencer/profile/[slug]/index.vue")
  },
  {
    name: "influencer-profile-slug___fr",
    path: "/fr/influencer/profile/:slug()/",
    meta: index2WRwFhZZSLMeta || {},
    component: () => import("/root/front/pages/influencer/profile/[slug]/index.vue")
  },
  {
    name: "influencer-profile-slug___de",
    path: "/de/influencer/profile/:slug()/",
    meta: index2WRwFhZZSLMeta || {},
    component: () => import("/root/front/pages/influencer/profile/[slug]/index.vue")
  },
  {
    name: "influencer-profile___en",
    path: "/en/influencer/profile/",
    meta: indexkWuHnlKNhzMeta || {},
    component: () => import("/root/front/pages/influencer/profile/index.vue")
  },
  {
    name: "influencer-profile___fr",
    path: "/fr/influencer/profile/",
    meta: indexkWuHnlKNhzMeta || {},
    component: () => import("/root/front/pages/influencer/profile/index.vue")
  },
  {
    name: "influencer-profile___de",
    path: "/de/influencer/profile/",
    meta: indexkWuHnlKNhzMeta || {},
    component: () => import("/root/front/pages/influencer/profile/index.vue")
  },
  {
    name: "influencer-signup-discovery___en",
    path: "/en/influencer/signup/discovery/",
    component: () => import("/root/front/pages/influencer/signup/discovery.vue")
  },
  {
    name: "influencer-signup-discovery___fr",
    path: "/fr/influencer/signup/discovery/",
    component: () => import("/root/front/pages/influencer/signup/discovery.vue")
  },
  {
    name: "influencer-signup-discovery___de",
    path: "/de/influencer/signup/discovery/",
    component: () => import("/root/front/pages/influencer/signup/discovery.vue")
  },
  {
    name: "influencer-signup___en",
    path: "/en/influencer/signup/",
    meta: indexXZtYyuwedLMeta || {},
    component: () => import("/root/front/pages/influencer/signup/index.vue")
  },
  {
    name: "influencer-signup___fr",
    path: "/fr/influencer/signup/",
    meta: indexXZtYyuwedLMeta || {},
    component: () => import("/root/front/pages/influencer/signup/index.vue")
  },
  {
    name: "influencer-signup___de",
    path: "/de/influencer/signup/",
    meta: indexXZtYyuwedLMeta || {},
    component: () => import("/root/front/pages/influencer/signup/index.vue")
  },
  {
    name: "influencer-signup-v2-bio___en",
    path: "/en/influencer/signup/v2/bio/",
    meta: bioUyHCCsBWTgMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/bio.vue")
  },
  {
    name: "influencer-signup-v2-bio___fr",
    path: "/fr/influencer/signup/v2/bio/",
    meta: bioUyHCCsBWTgMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/bio.vue")
  },
  {
    name: "influencer-signup-v2-bio___de",
    path: "/de/influencer/signup/v2/bio/",
    meta: bioUyHCCsBWTgMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/bio.vue")
  },
  {
    name: "influencer-signup-v2-genres___en",
    path: "/en/influencer/signup/v2/genres/",
    meta: genreskpWSMDOngPMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/genres.vue")
  },
  {
    name: "influencer-signup-v2-genres___fr",
    path: "/fr/influencer/signup/v2/genres/",
    meta: genreskpWSMDOngPMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/genres.vue")
  },
  {
    name: "influencer-signup-v2-genres___de",
    path: "/de/influencer/signup/v2/genres/",
    meta: genreskpWSMDOngPMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/genres.vue")
  },
  {
    name: "influencer-signup-v2-lang-lyrics-release___en",
    path: "/en/influencer/signup/v2/lang-lyrics-release/",
    meta: lang_45lyrics_45releasehv4xqoQVm0Meta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/lang-lyrics-release.vue")
  },
  {
    name: "influencer-signup-v2-lang-lyrics-release___fr",
    path: "/fr/influencer/signup/v2/lang-lyrics-release/",
    meta: lang_45lyrics_45releasehv4xqoQVm0Meta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/lang-lyrics-release.vue")
  },
  {
    name: "influencer-signup-v2-lang-lyrics-release___de",
    path: "/de/influencer/signup/v2/lang-lyrics-release/",
    meta: lang_45lyrics_45releasehv4xqoQVm0Meta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/lang-lyrics-release.vue")
  },
  {
    name: "influencer-signup-v2-name-country___en",
    path: "/en/influencer/signup/v2/name-country/",
    meta: name_45countryuYbGVBGhlNMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/name-country.vue")
  },
  {
    name: "influencer-signup-v2-name-country___fr",
    path: "/fr/influencer/signup/v2/name-country/",
    meta: name_45countryuYbGVBGhlNMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/name-country.vue")
  },
  {
    name: "influencer-signup-v2-name-country___de",
    path: "/de/influencer/signup/v2/name-country/",
    meta: name_45countryuYbGVBGhlNMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/name-country.vue")
  },
  {
    name: "influencer-signup-v2-picture___en",
    path: "/en/influencer/signup/v2/picture/",
    meta: pictureqCbTcxl8JFMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/picture.vue")
  },
  {
    name: "influencer-signup-v2-picture___fr",
    path: "/fr/influencer/signup/v2/picture/",
    meta: pictureqCbTcxl8JFMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/picture.vue")
  },
  {
    name: "influencer-signup-v2-picture___de",
    path: "/de/influencer/signup/v2/picture/",
    meta: pictureqCbTcxl8JFMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/picture.vue")
  },
  {
    name: "influencer-signup-v2-services___en",
    path: "/en/influencer/signup/v2/services/",
    meta: servicesHoRczAgNIcMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/services.vue")
  },
  {
    name: "influencer-signup-v2-services___fr",
    path: "/fr/influencer/signup/v2/services/",
    meta: servicesHoRczAgNIcMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/services.vue")
  },
  {
    name: "influencer-signup-v2-services___de",
    path: "/de/influencer/signup/v2/services/",
    meta: servicesHoRczAgNIcMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/services.vue")
  },
  {
    name: "influencer-signup-v2-similar-artists___en",
    path: "/en/influencer/signup/v2/similar-artists/",
    meta: similar_45artistsf3NkRSEKPAMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/similar-artists.vue")
  },
  {
    name: "influencer-signup-v2-similar-artists___fr",
    path: "/fr/influencer/signup/v2/similar-artists/",
    meta: similar_45artistsf3NkRSEKPAMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/similar-artists.vue")
  },
  {
    name: "influencer-signup-v2-similar-artists___de",
    path: "/de/influencer/signup/v2/similar-artists/",
    meta: similar_45artistsf3NkRSEKPAMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/similar-artists.vue")
  },
  {
    name: "influencer-signup-v2-type___en",
    path: "/en/influencer/signup/v2/type/",
    meta: typefUVyV1H9yDMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/type.vue")
  },
  {
    name: "influencer-signup-v2-type___fr",
    path: "/fr/influencer/signup/v2/type/",
    meta: typefUVyV1H9yDMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/type.vue")
  },
  {
    name: "influencer-signup-v2-type___de",
    path: "/de/influencer/signup/v2/type/",
    meta: typefUVyV1H9yDMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/type.vue")
  },
  {
    name: "influencer-signup-v2-website-links___en",
    path: "/en/influencer/signup/v2/website-links/",
    meta: website_45linksVKP4oURc4eMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/website-links.vue")
  },
  {
    name: "influencer-signup-v2-website-links___fr",
    path: "/fr/influencer/signup/v2/website-links/",
    meta: website_45linksVKP4oURc4eMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/website-links.vue")
  },
  {
    name: "influencer-signup-v2-website-links___de",
    path: "/de/influencer/signup/v2/website-links/",
    meta: website_45linksVKP4oURc4eMeta || {},
    component: () => import("/root/front/pages/influencer/signup/v2/website-links.vue")
  },
  {
    name: walletzyZvnk6DgNMeta?.name,
    path: "/en/influencer/wallet/",
    meta: walletzyZvnk6DgNMeta || {},
    component: () => import("/root/front/pages/influencer/wallet.vue"),
    children: [
  {
    name: "influencer-wallet___en",
    path: "",
    component: () => import("/root/front/pages/influencer/wallet/index.vue")
  },
  {
    name: "influencer-wallet-transfer___en",
    path: "transfer/",
    component: () => import("/root/front/pages/influencer/wallet/transfer.vue")
  },
  {
    name: "influencer-wallet-withdraw___en",
    path: "withdraw/",
    component: () => import("/root/front/pages/influencer/wallet/withdraw.vue")
  }
]
  },
  {
    name: walletzyZvnk6DgNMeta?.name,
    path: "/fr/influencer/wallet/",
    meta: walletzyZvnk6DgNMeta || {},
    component: () => import("/root/front/pages/influencer/wallet.vue"),
    children: [
  {
    name: "influencer-wallet___fr",
    path: "",
    component: () => import("/root/front/pages/influencer/wallet/index.vue")
  },
  {
    name: "influencer-wallet-transfer___fr",
    path: "transfer/",
    component: () => import("/root/front/pages/influencer/wallet/transfer.vue")
  },
  {
    name: "influencer-wallet-withdraw___fr",
    path: "withdraw/",
    component: () => import("/root/front/pages/influencer/wallet/withdraw.vue")
  }
]
  },
  {
    name: walletzyZvnk6DgNMeta?.name,
    path: "/de/influencer/wallet/",
    meta: walletzyZvnk6DgNMeta || {},
    component: () => import("/root/front/pages/influencer/wallet.vue"),
    children: [
  {
    name: "influencer-wallet___de",
    path: "",
    component: () => import("/root/front/pages/influencer/wallet/index.vue")
  },
  {
    name: "influencer-wallet-transfer___de",
    path: "transfer/",
    component: () => import("/root/front/pages/influencer/wallet/transfer.vue")
  },
  {
    name: "influencer-wallet-withdraw___de",
    path: "withdraw/",
    component: () => import("/root/front/pages/influencer/wallet/withdraw.vue")
  }
]
  },
  {
    name: "influencer-widget-slug___en",
    path: "/en/influencer/widget/:slug()/",
    meta: _91slug_93Hajv2S5MHPMeta || {},
    component: () => import("/root/front/pages/influencer/widget/[slug].vue")
  },
  {
    name: "influencer-widget-slug___fr",
    path: "/fr/influencer/widget/:slug()/",
    meta: _91slug_93Hajv2S5MHPMeta || {},
    component: () => import("/root/front/pages/influencer/widget/[slug].vue")
  },
  {
    name: "influencer-widget-slug___de",
    path: "/de/influencer/widget/:slug()/",
    meta: _91slug_93Hajv2S5MHPMeta || {},
    component: () => import("/root/front/pages/influencer/widget/[slug].vue")
  },
  {
    name: "influencer-widget___en",
    path: "/en/influencer/widget/",
    meta: indexWaqoQ0uhHxMeta || {},
    component: () => import("/root/front/pages/influencer/widget/index.vue")
  },
  {
    name: "influencer-widget___fr",
    path: "/fr/influencer/widget/",
    meta: indexWaqoQ0uhHxMeta || {},
    component: () => import("/root/front/pages/influencer/widget/index.vue")
  },
  {
    name: "influencer-widget___de",
    path: "/de/influencer/widget/",
    meta: indexWaqoQ0uhHxMeta || {},
    component: () => import("/root/front/pages/influencer/widget/index.vue")
  },
  {
    name: "influencer-widget-redirect-slug___en",
    path: "/en/influencer/widget/redirect/:slug()/",
    meta: _91slug_9326X1Ll7L2QMeta || {},
    component: () => import("/root/front/pages/influencer/widget/redirect/[slug].vue")
  },
  {
    name: "influencer-widget-redirect-slug___fr",
    path: "/fr/influencer/widget/redirect/:slug()/",
    meta: _91slug_9326X1Ll7L2QMeta || {},
    component: () => import("/root/front/pages/influencer/widget/redirect/[slug].vue")
  },
  {
    name: "influencer-widget-redirect-slug___de",
    path: "/de/influencer/widget/redirect/:slug()/",
    meta: _91slug_9326X1Ll7L2QMeta || {},
    component: () => import("/root/front/pages/influencer/widget/redirect/[slug].vue")
  },
  {
    name: "lp-slug___en",
    path: "/en/lp/:slug()/",
    meta: _91slug_93edncuuTnWGMeta || {},
    component: () => import("/root/front/pages/lp/[slug].vue")
  },
  {
    name: "lp-slug___fr",
    path: "/fr/lp/:slug()/",
    meta: _91slug_93edncuuTnWGMeta || {},
    component: () => import("/root/front/pages/lp/[slug].vue")
  },
  {
    name: "lp-slug___de",
    path: "/de/lp/:slug()/",
    meta: _91slug_93edncuuTnWGMeta || {},
    component: () => import("/root/front/pages/lp/[slug].vue")
  },
  {
    name: "partners___en",
    path: "/en/partners/",
    component: () => import("/root/front/pages/partners.vue")
  },
  {
    name: "partners___fr",
    path: "/fr/partners/",
    component: () => import("/root/front/pages/partners.vue")
  },
  {
    name: "partners___de",
    path: "/de/partners/",
    component: () => import("/root/front/pages/partners.vue")
  },
  {
    name: "payin-failure___en",
    path: "/en/payin/failure/",
    meta: failure1Lx2Eim0hwMeta || {},
    component: () => import("/root/front/pages/payin/failure.vue")
  },
  {
    name: "payin-failure___fr",
    path: "/fr/payin/failure/",
    meta: failure1Lx2Eim0hwMeta || {},
    component: () => import("/root/front/pages/payin/failure.vue")
  },
  {
    name: "payin-failure___de",
    path: "/de/payin/failure/",
    meta: failure1Lx2Eim0hwMeta || {},
    component: () => import("/root/front/pages/payin/failure.vue")
  },
  {
    name: "payin-paypal-retry___en",
    path: "/en/payin/paypal/retry/",
    component: () => import("/root/front/pages/payin/paypal/retry.vue")
  },
  {
    name: "payin-paypal-retry___fr",
    path: "/fr/payin/paypal/retry/",
    component: () => import("/root/front/pages/payin/paypal/retry.vue")
  },
  {
    name: "payin-paypal-retry___de",
    path: "/de/payin/paypal/retry/",
    component: () => import("/root/front/pages/payin/paypal/retry.vue")
  },
  {
    name: "pride___en",
    path: "/en/pride/",
    component: () => import("/root/front/pages/pride.vue")
  },
  {
    name: "pride___fr",
    path: "/fr/pride/",
    component: () => import("/root/front/pages/pride.vue")
  },
  {
    name: "pride___de",
    path: "/de/pride/",
    component: () => import("/root/front/pages/pride.vue")
  },
  {
    name: "purge___en",
    path: "/en/purge/",
    component: () => import("/root/front/pages/purge.vue")
  },
  {
    name: "purge___fr",
    path: "/fr/purge/",
    component: () => import("/root/front/pages/purge.vue")
  },
  {
    name: "purge___de",
    path: "/de/purge/",
    component: () => import("/root/front/pages/purge.vue")
  },
  {
    name: "reset-password___en",
    path: "/en/reset-password/",
    meta: reset_45passwordup3j5I624DMeta || {},
    component: () => import("/root/front/pages/reset-password.vue")
  },
  {
    name: "reset-password___fr",
    path: "/fr/reset-password/",
    meta: reset_45passwordup3j5I624DMeta || {},
    component: () => import("/root/front/pages/reset-password.vue")
  },
  {
    name: "reset-password___de",
    path: "/de/reset-password/",
    meta: reset_45passwordup3j5I624DMeta || {},
    component: () => import("/root/front/pages/reset-password.vue")
  },
  {
    name: "signup___en",
    path: "/en/signup/",
    meta: signuppt6CNYSyZ8Meta || {},
    component: () => import("/root/front/pages/signup.vue")
  },
  {
    name: "signup___fr",
    path: "/fr/signup/",
    meta: signuppt6CNYSyZ8Meta || {},
    component: () => import("/root/front/pages/signup.vue")
  },
  {
    name: "signup___de",
    path: "/de/signup/",
    meta: signuppt6CNYSyZ8Meta || {},
    component: () => import("/root/front/pages/signup.vue")
  },
  {
    name: "spotify-playlist-id___en",
    path: "/en/spotify/playlist/:id()/",
    component: () => import("/root/front/pages/spotify/playlist/[id]/index.vue")
  },
  {
    name: "spotify-playlist-id___fr",
    path: "/fr/spotify/playlist/:id()/",
    component: () => import("/root/front/pages/spotify/playlist/[id]/index.vue")
  },
  {
    name: "spotify-playlist-id___de",
    path: "/de/spotify/playlist/:id()/",
    component: () => import("/root/front/pages/spotify/playlist/[id]/index.vue")
  },
  {
    name: "spotify-playlist-id-loading___en",
    path: "/en/spotify/playlist/:id()/loading/",
    component: () => import("/root/front/pages/spotify/playlist/[id]/loading.vue")
  },
  {
    name: "spotify-playlist-id-loading___fr",
    path: "/fr/spotify/playlist/:id()/loading/",
    component: () => import("/root/front/pages/spotify/playlist/[id]/loading.vue")
  },
  {
    name: "spotify-playlist-id-loading___de",
    path: "/de/spotify/playlist/:id()/loading/",
    component: () => import("/root/front/pages/spotify/playlist/[id]/loading.vue")
  },
  {
    name: "spotify-share-track___en",
    path: "/en/spotify/share/track/",
    meta: trackLwiFtKkZOUMeta || {},
    component: () => import("/root/front/pages/spotify/share/track.vue")
  },
  {
    name: "spotify-share-track___fr",
    path: "/fr/spotify/share/track/",
    meta: trackLwiFtKkZOUMeta || {},
    component: () => import("/root/front/pages/spotify/share/track.vue")
  },
  {
    name: "spotify-share-track___de",
    path: "/de/spotify/share/track/",
    meta: trackLwiFtKkZOUMeta || {},
    component: () => import("/root/front/pages/spotify/share/track.vue")
  },
  {
    name: "test___en",
    path: "/en/test/",
    meta: test3ADM2ezGWaMeta || {},
    component: () => import("/root/front/pages/test.vue")
  },
  {
    name: "test___fr",
    path: "/fr/test/",
    meta: test3ADM2ezGWaMeta || {},
    component: () => import("/root/front/pages/test.vue")
  },
  {
    name: "test___de",
    path: "/de/test/",
    meta: test3ADM2ezGWaMeta || {},
    component: () => import("/root/front/pages/test.vue")
  }
]