import { useDraftStore } from '~/stores/draft'
import { useUserStore } from '~/stores/user'

const dontResetDraftOnRoutes = [
  '/band/payin',
  '/band/sent',
  '/draft/add/link',
  '/draft/influencers',
  '/influencer/profile',
  '/payin/failure',
]

export default defineNuxtRouteMiddleware(async (to) => {
  const { $sentry, $pinia } = useNuxtApp()
  const userStore = useUserStore($pinia)

  if (
    !userStore.IS_BAND ||
    dontResetDraftOnRoutes.some((r) => to.path.includes(r)) ||
    to.path.includes(`/draft/${to.params.id}`) ||
    to.fullPath.match(/draftId=\d+/g)
  )
    return

  const draftStore = useDraftStore($pinia)

  try {
    if (draftStore.id) await draftStore.RESET_CURRENT()
  } catch (err) {
    $sentry?.captureException(err)
  }
})
