export const availableLanguages = [
  'fr',
  'en-US',
  'en-GB',
  'de',
  // TODO LANGUAGE Un-comment when we deploy those languages
  // 'pt',
  // 'es',
  // 'it',
] as const

/**
 * Not to be confused with locale! A locale feeds into the vue i18n instance but a lang doesn't always match
 * Rule of thumb: Language is for the user, locale is for the text.
 */
export type Language = (typeof availableLanguages)[number]
