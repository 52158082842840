import type { LocaleObject } from '@nuxtjs/i18n'

export const LocaleDefintions: LocaleObject[] = [
  {
    code: 'en',
    file: 'en.json',
    name: 'English',
    language: 'en-US',
  },
  {
    code: 'fr',
    file: 'fr.json',
    name: 'Français',
    language: 'fr-FR',
  },
  {
    code: 'de',
    file: 'de.json',
    name: 'Deutsch',
    language: 'de',
  },
  // TODO LANGUAGE Un-comment when we deploy those languages
  // {
  //   code: 'es',
  //   file: 'es.json',
  //   name: 'Español',
  //   language: 'es-ES',
  // },
  // {
  //   code: 'pt',
  //   file: 'pt.json',
  //   name: 'Português',
  //   language: 'pt-PT',
  // },
  // {
  //   code: 'it',
  //   file: 'it.json',
  //   name: 'Italiano',
  //   language: 'it-IT',
  // },
]
