import payloadToJson_f85A5nYSY0 from "/root/front/plugins/payloadToJson.ts";
import revive_payload_client_ZU4BZ14GEU from "/root/front/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_ru5dpfvmgoph4xmj2djgyfsqmq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_WnMicZKKQh from "/root/front/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_ru5dpfvmgoph4xmj2djgyfsqmq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_x4zuxCvDCW from "/root/front/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_ru5dpfvmgoph4xmj2djgyfsqmq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_2jj48yrJ0O from "/root/front/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.24.4_vite@5.4.6_@types+node@20.17.6_sass@1.80_pg4y6wmjn55qjn7f47lwuzmbc4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_qmc4GdhuXr from "/root/front/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_ru5dpfvmgoph4xmj2djgyfsqmq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_HdAYI1ujp7 from "/root/front/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_ru5dpfvmgoph4xmj2djgyfsqmq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_juP0a6RZNw from "/root/front/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_ru5dpfvmgoph4xmj2djgyfsqmq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_LVbtDsytPb from "/root/front/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.4_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/root/front/.nuxt/components.plugin.mjs";
import prefetch_client_l8lfEgUcfN from "/root/front/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@20.17.6_eslint@8.57.1_ioredis@5.4.1_magicast@_ru5dpfvmgoph4xmj2djgyfsqmq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_cMclGs71Rl from "/root/front/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.24.4_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_1J3oVLWpv6 from "/root/front/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.24.4_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_wy0B721ODc from "/root/front/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import polyfills_client_xo6BY28vLt from "/root/front/plugins/polyfills.client.ts";
import globalNuxtLink_8h7uhS7dNV from "/root/front/plugins/globalNuxtLink.ts";
import sentry_3AyO8nEfhE from "/root/front/plugins/sentry.ts";
import fetch_14DdzPiXUB from "/root/front/plugins/fetch.ts";
import pinia_48xmdi2HHl from "/root/front/plugins/pinia.ts";
import globalMixin_0vW6V1IUxN from "/root/front/plugins/globalMixin.ts";
import initCsrf_GudLqtAxMz from "/root/front/plugins/initCsrf.ts";
import segment_xRnKTSkpmU from "/root/front/plugins/segment.ts";
import segmentPageTracking_CDhc5tQIUy from "/root/front/plugins/segmentPageTracking.ts";
import closeCookiebotDialog_wHIJHWT4P3 from "/root/front/plugins/closeCookiebotDialog.ts";
import growthBook_T0cxi0ahK4 from "/root/front/plugins/growthBook.ts";
import stripe_6FZUdDHpIZ from "/root/front/plugins/stripe.ts";
import vueGtag_qdVBVzWkdt from "/root/front/plugins/vueGtag.ts";
import preview_6oGpsaen2C from "/root/front/plugins/preview.ts";
export default [
  payloadToJson_f85A5nYSY0,
  revive_payload_client_ZU4BZ14GEU,
  unhead_WnMicZKKQh,
  router_x4zuxCvDCW,
  _0_siteConfig_2jj48yrJ0O,
  payload_client_qmc4GdhuXr,
  navigation_repaint_client_HdAYI1ujp7,
  chunk_reload_client_juP0a6RZNw,
  plugin_vue3_LVbtDsytPb,
  components_plugin_KR1HBZs4kY,
  prefetch_client_l8lfEgUcfN,
  switch_locale_path_ssr_cMclGs71Rl,
  i18n_1J3oVLWpv6,
  plugin_wy0B721ODc,
  polyfills_client_xo6BY28vLt,
  globalNuxtLink_8h7uhS7dNV,
  sentry_3AyO8nEfhE,
  fetch_14DdzPiXUB,
  pinia_48xmdi2HHl,
  globalMixin_0vW6V1IUxN,
  initCsrf_GudLqtAxMz,
  segment_xRnKTSkpmU,
  segmentPageTracking_CDhc5tQIUy,
  closeCookiebotDialog_wHIJHWT4P3,
  growthBook_T0cxi0ahK4,
  stripe_6FZUdDHpIZ,
  vueGtag_qdVBVzWkdt,
  preview_6oGpsaen2C
]