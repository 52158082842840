import { acceptHMRUpdate, defineStore, getActivePinia } from 'pinia'

import { useCartStore } from './cart'
import { useDraftStore } from './draft'
import { useMiscBandSignupReferralStore } from './miscBandSignupReferral'
import { useMiscDraftProgressivePromosStore } from './miscDraftProgressivePromos'
import { useMiscSendtrackStore } from './miscSendtrack'

import { computePercentPromotionToRemoveOnCost } from '~/helpers/promos'
import { resetStoreToInitialState } from '~/helpers/resetStoreToInitialState'

import type { ProgressivePromo } from '~/types/product'

const initialState = () => ({
  id: 0,
  value: 0,
  code: '',
  is_percentage: false,
  progressive_parameters: undefined as
    | undefined
    | ProgressivePromo['progressive_parameters'],
})

const state = initialState
export type DraftPromoState = ReturnType<typeof state>

export const useDraftPromoStore = defineStore('draftPromo', {
  state: (): DraftPromoState => ({ ...initialState() }),
  actions: {
    SET(patch: Partial<Record<keyof DraftPromoState, any>>) {
      for (const key in state()) {
        if (
          typeof this[key as keyof DraftPromoState] ===
          typeof patch[key as keyof DraftPromoState]
        )
          // TODO: check this
          // @ts-expect-error frr
          this[key] = patch[key]
        else if (key === 'value' && patch[key] === null)
          // make sure null values reset the 'value' key to 0 so we don't accidentally
          // keep old values that shouldn't be kept
          this[key] = 0
      }
    },
    async PROBE({
      code,
      onlyApplyIfBetter,
    }: {
      code: string
      onlyApplyIfBetter?: boolean
    }): Promise<void> {
      const pinia = getActivePinia()
      const miscDraftProgressivePromoStore =
        useMiscDraftProgressivePromosStore(pinia)
      const draftStore = useDraftStore(pinia)
      const miscSendtrackStore = useMiscSendtrackStore(pinia)

      if (
        miscDraftProgressivePromoStore.list
          .map((promo) => promo.code)
          .includes(code)
      ) {
        const promo = await this.CHECK_FOR_PROGRESSIVE_PROMO()

        if (promo === null) {
          await this.RESET()
          throw new Error('Invalid promo code')
        } else {
          try {
            await draftStore.UPDATE()
          } catch (_) {}
        }
      } else if (code !== this.code) {
        let hasInvalidPercent = false

        try {
          const response = await $coreFetch.$post<DraftPromoState>(
            '/wallet/promo/',
            {
              code,
            },
          )

          if (!onlyApplyIfBetter || response.value > this.value)
            this.SET(response)

          if (!this.IS_VALID_PERCENT) {
            miscSendtrackStore.SET_PROMO_USED_WITH_INVALID_PERCENT(response)
            hasInvalidPercent = true
            throw new Error('Percent not valid')
          } else {
            miscSendtrackStore.SET_PROMO_USED_WITH_INVALID_PERCENT(null)

            try {
              await draftStore.UPDATE(undefined)
            } catch (_) {}
          }
        } catch (err) {
          // if there's an error like a 404 that causes this catch to run
          // make sure to set the `SET_PROMO_USED_WITH_INVALID_PERCENT` to null
          if (!hasInvalidPercent)
            miscSendtrackStore.SET_PROMO_USED_WITH_INVALID_PERCENT(null)

          await this.RESET()
          throw err
        }
      } else if (this.IS_VALID_PERCENT) {
        // Do nothing
      } else {
        throw new Error('Percent not valid')
      }
    },
    TEST_VALIDITY() {
      return $coreFetch.$post('/wallet/promo/', {
        code: this.code,
      })
    },
    async RESET({ noServerUpdate = false } = {}): Promise<void> {
      const draftStore = useDraftStore(getActivePinia())

      if (noServerUpdate) {
        resetStoreToInitialState.bind(this)(initialState())
        return
      }

      await $coreFetch.$patch(`/submission/draft/${draftStore.id}/`, {
        promo: null,
      })
      resetStoreToInitialState.bind(this)(initialState())
    },
    async CHECK_FOR_PROGRESSIVE_PROMO(): Promise<ProgressivePromo | null> {
      const pinia = getActivePinia()
      const miscDraftProgressivePromosStore =
        useMiscDraftProgressivePromosStore(pinia)
      const miscBandSignupReferralStore = useMiscBandSignupReferralStore(pinia)
      const draftStore = useDraftStore(pinia)

      if (draftStore.id === 0 || !miscDraftProgressivePromosStore.IS_ENABLED)
        return null

      const eligibleProgressivePromo =
        miscDraftProgressivePromosStore.PROGRESSIVE_PROMO_FROM_INF_COUNT(
          draftStore.influencers_count,
        )
      const CART_COST_AFTER_PROMO = this.CART_COST_AFTER_PROMO
      const CART_COST_AFTER_PROGRESSIVE_PROMO =
        miscDraftProgressivePromosStore.CART_COST_AFTER_PROGRESSIVE_PROMO

      if (!eligibleProgressivePromo && this.IS_PROGRESSIVE_PROMO) {
        // edge case: when a progressive promo was applied but user removed curators from draft and no progressive promo is eligible anymore
        await this.RESET()
        return null
      } else if (!eligibleProgressivePromo) {
        return null
      }

      if (CART_COST_AFTER_PROGRESSIVE_PROMO < CART_COST_AFTER_PROMO) {
        // when the new progressive promo should be applied
        this.SET(eligibleProgressivePromo)
        this.UPDATE_PROGRESSIVE_PROMO()
        return eligibleProgressivePromo
      } else if (
        CART_COST_AFTER_PROGRESSIVE_PROMO !== CART_COST_AFTER_PROMO &&
        this.IS_PROGRESSIVE_PROMO
      ) {
        // TODO: find out if this is necessary. Leaving here just in case but there doesn't seem to be a usecase for it
        // We reset any progressive promo that wouldn't be eligible anymore
        await this.RESET() // We don't update server because progressive promos are not handled by the same route as conventional promos

        // Set referral and attempt next best progressive
        await miscBandSignupReferralStore.PUSH_CODE_TO_PROMO()
        return await this.CHECK_FOR_PROGRESSIVE_PROMO()
      }

      return eligibleProgressivePromo || null
    },
    async UPDATE_PROGRESSIVE_PROMO() {
      const draftStore = useDraftStore(getActivePinia())
      const promo = this.id || null

      await $coreFetch
        .$patch(`/submission/draft/${draftStore.id}/`, {
          promo,
        })
        .catch()
      if (!promo) this.SET(initialState())
    },
  },
  getters: {
    TO_REMOVE(state): number {
      const cartStore = useCartStore(getActivePinia())
      const baseCost: number = cartStore.COST

      if (state.id > 0 && state.code !== '' && this.IS_VALID_PERCENT) {
        if (state.is_percentage) {
          return computePercentPromotionToRemoveOnCost({
            baseCost,
            value: state.value,
          })
        } else {
          return Math.min(state.value, baseCost)
        }
      } else {
        return 0
      }
    },
    CART_COST_AFTER_PROMO(state): number {
      const pinia = getActivePinia()
      const cartStore = useCartStore(pinia)
      const draftStore = useDraftStore(pinia)
      const baseCost = cartStore.COST + draftStore.boosted_cost

      if (state.id > 0) return baseCost - this.TO_REMOVE
      else return baseCost
    },
    IS_VALID_PERCENT(state) {
      const cartStore = useCartStore(getActivePinia())

      if (state.id > 0 && state.is_percentage)
        return cartStore.COST >= Math.round(100 / state.value)
      else return true
    },
    IS_PROGRESSIVE_PROMO(state) {
      const miscDraftProgressivePromosStore =
        useMiscDraftProgressivePromosStore(getActivePinia())
      return miscDraftProgressivePromosStore.list
        .map((promo) => promo.code)
        .includes(state.code)
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDraftPromoStore, import.meta.hot))
